function NavBar() {
    return (
        <>
            <div id="navbarId">
                <b>Portfolio</b>
                <a href="#profileId" className="noMob">Home</a>
                <a href="#projectsId" className="noMob">Projects</a>
                <a href="#servicesId" className="noMob">Experience</a>
                <a href="#testimonialsId" className="noMob">Testimonials</a>
                <a href="#contactId" className="noMob">Contact</a>
            </div>
            <div id="navbarMobId">
                <a href="#profileId">Home</a>
                <a href="#projectsId">Projects</a>
                <a href="#servicesId">Experience</a>
                <a href="#testimonialsId">Testimonials</a>
                <a href="#contactId">Contact</a>
            </div>
        </>
    );
}

export default NavBar;