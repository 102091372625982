import { Profile, Projects } from "./components";

function App() {
  return (
    <>
      <Profile/>
      <Projects/>
    </>
  );
}

export default App;
