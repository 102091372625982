import projects from './projectDetails.json';

function Projects () {
    console.log(projects);
    return (
        <>
            <div id="projectsId" >
                <h1>My Projects</h1>
                <div id='projectsContainer'>
                    {projects.map(project => {return <ProjectCard key={project.name} project={project} />})}
                </div>
            </div>
        </>
    )
}

export default Projects;

function ProjectCard ( {project} ) {
    return (
        <div className='projectBox' >
            <h3 className='colorblue'>{project.name}</h3>
            <p>{project.describtion}</p>
            {project.github && <a href={project.github} target='_blank'>GitHub</a>}
            {project.demo && <a href={project.demo} target='_blank'>Demo</a>}
        </div>
    )
}