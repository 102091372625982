import NavBar from "../navbar/NavBar";

function Profile() {
    return (
        <>
            <div id="topId">
                <NavBar/>
                <ProfileContainer/>
            </div>
        </>
    );
}

export default Profile;

function ProfileContainer() {
    return (
        <>
            <div id="profileId">
                <div>
                    <h4>Hello <span className="colorblue">I'am</span></h4>
                    <h1 className="colorblue" style={{margin: "10px 0"}}>Vishnu G</h1>
                    <h3>Full-Stack Developer | Hacker | Tester | Competative Programmer</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, iusto. Adipisci in qui officia provident tenetur sequi, deserunt corporis quos?</p>
                </div>
                <div>
                    <img src="https://avatars.githubusercontent.com/u/82099692?v=4" alt="profileImage" />
                </div>
            </div>
        </>
    )
}
